import tw from "twin.macro";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import branchImage from "../../assets/qrcode.png";
import styled from "styled-components";
import { devices } from "components/CommonComponent";


const ColumnHeading = tw.h6`uppercase text-white font-bold m-0`;
const LinkList = tw.ul`my-5 font-normal text-sm p-0`;
const LinkListItem = tw.li`list-none mt-2 text-gray-400`;
const Links = tw(Link)`no-underline pl-3 text-gray-400 hover:pl-4 pb-1`;
const SocialLinksContainer = tw.div`flex items-center mb-5`;

const QrImage = styled.img`
margin-top: -20px;
@media ${devices.tablet} {
  margin-top: -20px;
}
`
export default function Footer() {
  return (
    <>
      <footer tw="py-6 pb-0 lg:p-16  text-gray-400" >
        <div className="container">
          <div className="row m-auto">
            <div className="col-lg-4 col-md-4">
              <ColumnHeading>Quick Links</ColumnHeading>
              <LinkList>
                <LinkListItem>
                  <Links
                    to="/tractors"
                  >
                    Tractor
                  </Links>
                </LinkListItem>
                <LinkListItem>
                  <Links
                    to="/implement"
                  >
                    Implements
                  </Links>
                </LinkListItem>
                <LinkListItem>
                  <Links
                    to="/harvestor"
                  >
                    Harvestor
                  </Links>
                </LinkListItem>
                <LinkListItem>
                  <Links
                    to="/gallery"
                  >
                    Gallery
                  </Links>
                </LinkListItem>
                <LinkListItem>
                  <Links
                    to="/contact"
                  >
                    Contact
                  </Links>
                </LinkListItem>
                <LinkListItem>
                  <Links
                    to="/about"
                  >
                    About Us
                  </Links>
                </LinkListItem>
              </LinkList>
            </div>
            <div className="col-lg-4 col-md-4">
              <ColumnHeading>Head Office</ColumnHeading>
              <LinkList>
                <SocialLinksContainer>
                  <i className="fa fa-map-marker" tw="text-2xl" aria-hidden="true"></i> <p tw="ml-4 mb-0">No.146/3, Amaravathy Nagar, Bangalore Highway, Mamangam, Salem – 636302.</p>
                </SocialLinksContainer>
                <SocialLinksContainer>
                  <i className="fa fa-phone" tw="text-2xl" aria-hidden="true"></i>
                  <a href="tel:+91 96552 24481" className="underline">
                    <p tw="ml-4 mb-0">+91-96552 24481 </p>
                  </a>
                </SocialLinksContainer>
                <SocialLinksContainer>
                  <i className="fa fa-envelope" tw="text-2xl" aria-hidden="true"></i>
                  <a href="mailto:racessalem@yahoo.in" className="underline">
                    <p tw="ml-4 mb-0">
                      racessalem@yahoo.in</p>
                  </a>
                </SocialLinksContainer>
              </LinkList>
            </div>
            <div className="col-lg-4 col-md-4 text-center">
              <ColumnHeading>Scan Me</ColumnHeading>
              <QrImage src={branchImage} alt="scan-location" className="qr__image" width="250px" />
            </div>
          </div>
          <hr />
          <div className="row m-auto pt-6">
            <div className="col-lg-1 col-md-1">
              <img src={logo} alt="Zinger" className="footer-img" width='100px' />
            </div>
            <div className="col-lg-5 col-md-4">
              <p className="mt-1 mb-0 px-2 text-sm">Copyright © 2023  Rasi Infotech. All rights reserved.</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};