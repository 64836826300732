import React, { useState, useRef } from "react";
import Modal from 'react-modal';
import styled from "styled-components";
// import emailjs from '@emailjs/browser';
import "twin.macro";
import CloseToggleImg from "../assets/closetoggle.svg";
import Enquiry from "../assets/enquiry.jpg";
import { devices } from "./CommonComponent";

const CloseToggle = styled.span`
cursor: pointer;
`

const ImageDivContainer = styled.div`
width: 350px;
display: none;
@media ${devices.laptop}{
    display: block;
}
`
const DivContainer = styled.div`
display: flex;
justify-content: center;
`

const EnquiryImage = styled.img`
padding-top: 60px;
@media ${devices.laptopL}{
    padding-top: 0px;
}
`

export default function EnquiryModal(props) {

    const form = useRef();
    //eslint-disable-next-line
    console.log(props);
    const [modalIsOpen, setIsOpen] = useState(props.openModal);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleCloseModal = () => {
        props.closeModal(false);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        setIsOpen(prev => !prev)
        let responseData = ''
        if (props.modalData) {
            responseData = `https://wa.me/919600233559?text=Regarding Enquiry About ${props?.modalData} %0a Hi, %0a I am ${name},First of all, I really like ${props.modalData} and want to make an enquiry regarding it.So Below i will attach my details %0a Mobile : ${phone} %0a Mail: ${email} %0a Message : ${message}`
        } else {
            responseData = `https://wa.me/919600233559?text=Regarding Enquiry About Implements %0a Hi, %0a I am ${name},First of all, I really like Implements and want to make an enquiry regarding it.So Below i will attach my details %0a Mobile : ${phone} %0a Mail: ${email} %0a Message : ${message}`
        }
        window.open(responseData);
    };

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                contentLabel="onRequestClose Example"
                onRequestClose={handleCloseModal}
                shouldCloseOnOverlayClick={true}
            >
                <CloseToggle onClick={handleCloseModal} style={{ float: "right" }}>
                    <img src={CloseToggleImg} alt="close-toggle" />
                </CloseToggle>
                <h4 className="text-center py-4">Make an Enquiry</h4>
                <div className="row">
                    <ImageDivContainer className="col">
                        <EnquiryImage src={Enquiry} alt="enquiry" width="100%" />
                    </ImageDivContainer>
                    <DivContainer className="col my-4 mx-2">
                        <form ref={form} onSubmit={sendEmail}>
                            <div class="group">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Name</label>
                            </div>
                            <div class="group">
                                <input
                                    type="text"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    required />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Phone No</label>
                            </div>
                            <div class="group">
                                <input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    name="user_mail"
                                    required />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Email</label>
                            </div>
                            <div class="group">
                                <input
                                    type="textarea"
                                    name="user_msg"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required />
                                <span class="highlight"></span>
                                <span class="bar"></span>
                                <label>Message</label>
                            </div>
                            <div className="row">
                                <input type="submit" />
                            </div>
                        </form>
                    </DivContainer>
                </div>
            </Modal>
        </div>
    );
};